<template>
  <div class="register">
    <img src="@/assets/images/register-icon.png" alt="" class="register-icon" />
    <div class="register-panel">
      <h1 class="title">注册</h1>

      <a-form :form="form" :wrapper-col="{ span: 24 }" autoComplete="off">
        <a-form-item>
          <a-input
            size="large"
            placeholder="请输入手机号"
            v-decorator.trim="['phone', validatorRules.phone]"
          />
        </a-form-item>

        <a-form-item>
          <a-input
            size="large"
            type="password"
            placeholder="请输入6-18位登录密码"
            autocomplete="new-password"
            v-decorator.trim="['password', validatorRules.password]"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 24 }" class="form-code">
          <a-button
            class="start-code"
            block
            type="primary"
            v-if="!startCode"
            @click="handleStartCode"
          >
            <i class="iconfont icon-a-28Cdianji"></i>
            点击获取验证码
          </a-button>
          <template v-else>
            <a-row type="flex">
              <a-col span="16">
                <a-input
                  size="large"
                  class="verify-code-input"
                  placeholder="请输入短信验证码"
                  v-decorator.trim="['smsCode', validatorRules.smsCode]"
                  @search="getSmsCode"
                  @pressEnter="handleSubmit"
                >
                </a-input>
              </a-col>

              <a-col span="8">
                <a-button
                  size="large"
                  class="verify-code-button"
                  type="primary"
                  ghost
                  block
                  @click="getSmsCode"
                  :disabled="sendSmsCode"
                >
                  <span v-if="!sendSmsCode">获取验证码</span>
                  <span v-else>重新获取({{ countDownNum }}s)</span>
                </a-button>
              </a-col>
            </a-row>
          </template>
        </a-form-item>
        <a-row>
          <a-col :span="24">
            <a-button
              :loading="loading"
              size="large"
              type="primary"
              block
              shape="round"
              class="register-btn"
              @click="handleSubmit"
              >注册</a-button
            >
          </a-col>
          <a-col :span="8"></a-col>
        </a-row>
        <a-row justify="end" type="flex" class="login-tip">
          <span>已有账号</span>
          <router-link to="/login" class="go-login"> 去登录 </router-link>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import { smsCode, register } from "@/request/user";
import { Col, Form, Checkbox, Input, Row, Button } from "ant-design-vue";
import md5 from "md5";
// import { passwordRule } from "../../config/rules";
import { validatePwd } from "../../config/rules";

export default {
  name: "Register",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [Button.name]: Button,
    [Input.Search.name]: Input.Search,
  },
  data() {
    return {
      startCode: false,
      password: "",
      loading: false,
      imgContent: `/backend/login/code?${Math.random()}`,
      jsessionid2: "",
      countDownNum: 59,
      sendSmsCode: false,
      sendTimer: null,
      form: this.$form.createForm(this),
      validatorRules: {
        username: {
          rules: [{ required: true, message: "请输入您的用户名" }],
        },
        phone: {
          rules: [
            { required: true, message: "请输入您的手机号码" },
            {
              pattern: /^[1]([3-9])[0-9]{9}$/,
              message: "请输入正确的手机号码",
            },
          ],
        },
        smsCode: {
          rules: [{ required: true, message: "请输入短信验证码" }],
        },
        password: {
          rules: [
            { required: true, message: "请输入您的密码" },
            { validator: validatePwd },
          ],
        },
      },
    };
  },
  created() {},
  methods: {
    // changeImg() {
    //   this.imgContent = `/backend/login/code?${Math.random()}`;
    // },
    validatePhone() {
      let phoneNum = this.form.getFieldValue("phone");
      let reg = /^[1]([3-9])[0-9]{9}$/;
      if (!phoneNum) {
        message.error("请输入手机号码");
        return false;
      } else if (!reg.test(phoneNum)) {
        message.error("请输入正确的手机号码");
        return false;
      }
      return true;
    },
    // 开始获取验证码
    handleStartCode() {
      const isValidPhone = this.validatePhone();
      if (isValidPhone) {
        this.startCode = true;
        this.getSmsCode();
      }
    },
    getSmsCode() {
      let phoneNum = this.form.getFieldValue("phone");
      if (this.validatePhone()) {
        console.log("通过校验");
        let params = {
          phone: phoneNum,
        };
        smsCode(params).then(() => {
          message.success("验证码已发送");
          this.sendSmsCode = true;
          this.resendCountDown();
        });
      }
      // if (!phoneNum) {
      //   message.error("请输入手机号码");
      // } else if (!reg.test(phoneNum)) {
      //   message.error("请输入正确的手机号码");
      // } else {
      //   let params = {
      //     mobileNo: phoneNum,
      //   };
      //   smsCode(params).then(() => {
      //     message.success("发送成功");
      //     this.sendSmsCode = true;
      //     this.resendCountDown();
      //   });
      // }
    },
    resendCountDown() {
      this.sendTimer = setInterval(() => {
        this.countDownNum--;
        if (this.countDownNum <= 0) {
          this.sendSmsCode = false;
          this.countDownNum = 59;
          clearInterval(this.sendTimer);
        }
      }, 1000);
    },
    // validatePwd(rule, value, callback) {
    //   // this.password = value;
    //   let reg = passwordRule;
    //   if (!value) {
    //     callback();
    //   } else if (value.length < 8 || value.length > 16) {
    //     callback("密码长度为8-16位");
    //   } else if (!reg.test(value)) {
    //     callback("密码必须包含数字、大小写字母、特殊符号中的三种");
    //   } else {
    //     callback();
    //   }
    // },
    // handleConfirmPwd(rule, value, callback) {
    //   if (!value) {
    //     callback();
    //   } else if (this.password && this.password !== value) {
    //     callback("两次输入密码不一致");
    //   } else {
    //     callback();
    //   }
    // },
    // handleAgrmet(rule, value, callback) {
    //   if (value) callback();
    //   else callback("请同意宽维协同平台用户协议");
    // },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.smsCode) {
            this.loading = true;
            let params = {
              // account: values.username.trim(),
              password: md5(values.password.trim()),
              // email: values.email,
              phone: values.phone,
              smsCode: values.smsCode,
            };
            register(params)
              .then(() => {
                message.success("注册成功");
                this.loading = false;
                this.$router.replace("/login");
              })
              .catch(() => {
                this.loading = false;
                // this.changeImg();
              });
          } else {
            this.$myToast({
              type: "error",
              message: "请先获取验证码",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";
@import "@/styles/scss/variants.scss";

.has-error .ant-input,
.has-error .ant-input:hover,
.has-error .ant-input:focus {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-input {
  border: 1px solid transparent;

  &:focus {
    border-color: $primary-color;
  }
}

::v-deep .ant-form-explain,
.ant-form-extra {
  margin-bottom: 0;
  margin-top: 0px;
}

.register {
  @include flex();

  .register-icon {
    margin-top: 140px;
    margin-left: 164px;
    width: 586px;
    height: 546px;
    //background: linear-gradient(248deg, #f6bf75 0%, #d77185 31%, #00fffc 71%);
    //border-radius: 50%;
  }

  .register-panel {
    width: 558px;
    height: 588px;
    background: $white;
    opacity: 0.95;
    border-radius: 10px;
    padding: 64px 50px;
    margin: 140px 124px 0 auto;
    //@include flex(column);

    .title {
      color: $primary-text-color;
      font-size: $font-size-menu-title;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .ant-form-item {
      margin-bottom: 24px;

      ::v-deep .ant-input,
      ::v-deep .ant-btn {
        height: 56px;
      }

      ::v-deep .ant-input {
        background: #eef6ff;
        //border: none;

        //&:focus {
        //  border: 1px solid #3661eb;
        //}
      }
    }

    .form-code {
      margin-bottom: 56px;
    }

    .start-code {
      background: linear-gradient(180deg, #09bbfe 0%, #3661eb 100%);
      border-radius: 4px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      border: none;
      @include flex(row, center, center);

      .icon-a-28Cdianji {
        font-size: 30px;
        margin-right: 16px;
      }
    }

    .verify-code-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    .verify-code-button {
      background: linear-gradient(180deg, #09bbfe 0%, #5a42ec 100%) !important;
      font-size: 20px;
      color: $white;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &[disabled="disabled"] {
        opacity: 0.5;
      }
    }

    .register-btn {
      height: 66px;
      font-size: $font-size-card-title;
      letter-spacing: 2px;
      margin-bottom: auto;
    }

    .login-tip {
      flex: 1;
      margin-top: 24px;
      font-size: 20px;
      color: $primary-text-color;

      .go-login {
        color: $primary-color;
        margin-left: 10px;
      }
    }
  }
}

//.register {
//  //width: 100%;
//  //height: 100%;
//  padding-top: 0;
//  //padding-top: 46px;
//  //padding-bottom: 40px;
//  //margin-bottom: 120px;
//  @include flex();
//
//  .register-panel {
//    //width: 1200px;
//    margin-right: 123px;
//    margin-left: auto;
//
//    width: 558px;
//    height: 558px;
//    //margin: 0 auto;
//    background-color: #ffffff;
//    padding: 80px 0 100px 0;
//
//    h1.title {
//      text-align: center;
//      color: #333333;
//      font-size: 24px;
//      font-weight: bold;
//    }
//
//    ::v-deep form {
//      label {
//        font-size: 16px;
//        color: #666666;
//      }
//      .ant-input::placeholder {
//        color: #999999;
//        font-size: 14px;
//      }
//    }
//
//    .verify-img {
//      width: 100%;
//      height: 40px;
//      overflow: hidden;
//      position: relative;
//      img {
//        height: 100%;
//        height: 40px;
//        object-fit: cover;
//        position: absolute;
//        left: 50%;
//        top: 50%;
//        transform: translate(-50%, -50%);
//      }
//    }
//
//    .change {
//      width: 100%;
//      height: 40px;
//      line-height: 40px;
//      text-align: center;
//      span {
//        color: $primary-color;
//        font-size: 14px;
//        cursor: pointer;
//      }
//    }
//
//    .verify-code-input {
//      width: 62.5%;
//      border-top-right-radius: 0;
//      border-bottom-right-radius: 0;
//    }
//
//    .verify-code-button {
//      width: 37.5%;
//      border-top-left-radius: 0;
//      border-bottom-left-radius: 0;
//      margin-left: -1px;
//      top: -0.5px;
//      span {
//        font-size: 14px;
//      }
//    }
//
//    .pwd-strength {
//      position: absolute;
//      top: -10px;
//      right: -230px;
//    }
//
//    .agreement-box {
//      > .ant-col {
//        > .ant-row {
//          ::v-deep .ant-form-item-control-wrapper {
//            width: 400px;
//            .agreement {
//              font-size: 14px;
//              color: #333333;
//              a {
//                color: $primary-color;
//              }
//            }
//          }
//        }
//      }
//    }
//
//    .register-btn {
//      margin-bottom: 10px;
//      font-size: 18px;
//      height: 44px;
//      border-radius: 44px;
//    }
//
//    .go-login {
//      color: #333333;
//      font-size: 14px;
//      float: right;
//      a {
//        color: $primary-color;
//      }
//    }
//  }
//}
</style>
